import { Col, Container, Row } from 'react-bootstrap';

import Loader from '../../../../components/Loader';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import PageHeader from '../../../../components/PageHeader';

import GeneralDataCard from './components/GeneralDataCard';

import { ChangeEvent } from 'react';
import Button from '../../../../components/Button';
import { ButtonContainer } from '../../../../components/Form';
import { useAppContext } from '../../../../contexts/auth';
import renderArray from '../../../../utils/renderArrayWithComa';
import AddressDataCard from './components/AddressDataCard';
import AssociatedCompaniesModal from './components/AssociatedCompaniesModal';
import BankDataCard from './components/BankDataCard';
import ComplementaryData from './components/ComplementaryData';
import ContactDataCard from './components/ContactDataCard';
import CuponsModal from './components/CuponsModal';
import KindOfCompany from './components/KindOfCompany';
import useCompaniesForm from './useCompaniesForm';

interface CompaniesFormInterface {
  edit: boolean;
}

export default function CompaniesForm({ edit }: CompaniesFormInterface) {
  const {
    isLoading,
    getErrorMessageByFieldName,
    cnpj,
    handleCnpjChange,
    corporateName,
    handleCorporateNameChange,
    fantasyName,
    handleFantasyNameChange,
    contactName,
    handleContactNameChange,
    contactCellphone,
    handleContactCellphoneChange,
    contactEmail,
    handleContactEmailChange,
    cep,
    streetName,
    number,
    complement,
    district,
    city,
    uf,
    handleCepChange,
    handleNumberChange,
    handleComplementChange,
    pixType,
    pixOptions,
    handleChangePixType,
    cnpjPix,
    cpfPix,
    emailPix,
    cellphonePix,
    randomPix,
    banksOptions,
    selectedBank,
    handleSelectBank,
    handleCnpjPixChange,
    handleCpfPixChange,
    handleEmailPixChange,
    handleCellphonePixChange,
    handleRandomPixChange,
    agency,
    handleAgencyChange,
    account,
    handleAccountChange,
    cardholder,
    handleCardholderChange,
    cnpjBank,
    handleCnpjBankChange,
    isFormValid,
    employeesQuantity,
    handleEmployeesQuantityChange,
    elegiblesQuantity,
    handleEligibleQuantityChange,
    adherenceGoal,
    handleAderenceGoalChange,
    createCompany,
    updateCompany,
    isGettingCepInfo,
    monthlyFee,
    handleMonthlyFeeChange,
    contractDate,
    handleContractDateChange,
    startDate,
    handleStartDateChange,
    handleKindOfCompanyChange,
    kindOfCompany,
    associatedCompaniesModalShow,
    setAssociatedCompaniesModalShow,
    associatedCompanies,
    handleFileUpload,
    addOneAssociatedCompany,
    removeAssociatedCompany,
    authenticationType,
    setAuthenticationType,
    isReadOnly,
    setIsReadOnly,
    loginEmail,
    handleLoginEmailChange,
    password,
    handlePasswordChange,
    passwordConfirmation,
    handlePasswordConfirmationChange,
    companyBeingAdded,
    setCompanyBeingAdded,
    setCuponsModalShow,
    setPlanKind,
    planValue,
    handlePlanValueChange,
    planKind,
    planTrial,
    handlePlanTrialChange,
    cuponsModalShow,
    cupons,
    addCupom,
    removeCupom,
    cupomPlanKind,
    cupomPlanValue,
    cupomPlanTrial,
    cupomName,
    handleCupomPlanValueChange,
    handleCupomPlanTrialChange,
    handleCupomNameChange,
    setCupomPlanKind,
    getErrorMessageByFieldNameCupomForm,
    isCupomFormValid,
    setShowAtCompaniesListAppRegister,
    showAtCompaniesListAppRegister,
    setViewByAssociatedCompanies,
    viewByAssociatedCompanies,
    requiredFieldsErrorsLabel,
    cuponsOptions,
    associatedCuponsIds,
    setAssociatedCuponsIds,
    expires,
    setExpires,
    handleExpirationDateChange,
    expirationDate,
    willLimitUsage,
    setWillLimitUsage,
    handleUseLimitChange,
    useLimit,
    considerTestUntilTrialDays,
    setConsiderTestUntilTrialDays,
  } = useCompaniesForm({ edit });

  const { user: { permission } } = useAppContext();

  return (
    <>
      <Loader isLoading={isLoading} />
      {edit && (
        <PageHeader
          title={`Editar Empresa ${fantasyName}`}
          link="/companies"
        />
      )}
      <KindOfCompany
        handleKindOfCompanyChange={handleKindOfCompanyChange}
        kindOfCompany={kindOfCompany}
        setAssociatedCompaniesModalShow={setAssociatedCompaniesModalShow}
      />
      <Container>
        <Row xs={1} md={1} lg={2}>
          <Col>
            <OpacityAnimation>
              <GeneralDataCard
                getErrorMessageByFieldName={getErrorMessageByFieldName}
                cnpj={cnpj}
                onCnpjChange={handleCnpjChange}
                corporateName={corporateName}
                onCorporateNameChange={handleCorporateNameChange}
                fantasyName={fantasyName}
                onFantasyNameChange={handleFantasyNameChange}
                employeesQuantity={employeesQuantity}
                onEmployeesQuantityChange={handleEmployeesQuantityChange}
                elegiblesQuantity={elegiblesQuantity}
                onEligibleQuantityChange={handleEligibleQuantityChange}
                adherenceGoal={adherenceGoal}
                onAdherenceGoalChange={handleAderenceGoalChange}
              />
            </OpacityAnimation>
          </Col>
          <Col>
            <OpacityAnimation>
              <ContactDataCard
                getErrorMessageByFieldName={getErrorMessageByFieldName}
                contactName={contactName}
                handleContactNameChange={handleContactNameChange}
                contactCellphone={contactCellphone}
                handleContactCellphoneChange={handleContactCellphoneChange}
                contactEmail={contactEmail}
                handleContactEmailChange={handleContactEmailChange}
              />
            </OpacityAnimation>
          </Col>
        </Row>
        <Row xs={1} md={1} lg={1}>
          <Col>
            <OpacityAnimation>
              <AddressDataCard
                cep={cep}
                handleCepChange={handleCepChange}
                streetName={streetName}
                number={number}
                handleNumberChange={handleNumberChange}
                complement={complement}
                handleComplementChange={handleComplementChange}
                district={district}
                city={city}
                uf={uf}
                getErrorMessageByFieldName={getErrorMessageByFieldName}
                isGettingCepInfo={isGettingCepInfo}
              />
            </OpacityAnimation>
          </Col>
          <Col>
            <OpacityAnimation>
              <BankDataCard
                pixType={pixType}
                pixOptions={pixOptions}
                handleChangePixType={handleChangePixType}
                cnpjPix={cnpjPix}
                cpfPix={cpfPix}
                emailPix={emailPix}
                cellphonePix={cellphonePix}
                randomPix={randomPix}
                banksOptions={banksOptions}
                selectedBank={selectedBank}
                handleSelectBank={handleSelectBank}
                handleCnpjPixChange={handleCnpjPixChange}
                handleCpfPixChange={handleCpfPixChange}
                handleEmailPixChange={handleEmailPixChange}
                handleCellphonePixChange={handleCellphonePixChange}
                handleRandomPixChange={handleRandomPixChange}
                getErrorMessageByFieldName={getErrorMessageByFieldName}
                agency={agency}
                handleAgencyChange={handleAgencyChange}
                account={account}
                handleAccountChange={handleAccountChange}
                cardholder={cardholder}
                handleCardholderChange={handleCardholderChange}
                cnpjBank={cnpjBank}
                handleCnpjBankChange={handleCnpjBankChange}
              />
            </OpacityAnimation>
          </Col>
          <Col>
            <OpacityAnimation>
              <ComplementaryData
                monthlyFee={monthlyFee}
                handleMonthlyFeeChange={handleMonthlyFeeChange}
                contractDate={contractDate}
                handleContractDateChange={handleContractDateChange}
                getErrorMessageByFieldName={getErrorMessageByFieldName}
                startDate={startDate}
                handleStartDateChange={handleStartDateChange}
                authenticationType={authenticationType}
                setAuthenticationType={setAuthenticationType}
                isReadOnly={isReadOnly}
                setIsReadOnly={setIsReadOnly}
                loginEmail={loginEmail}
                handleLoginEmailChange={handleLoginEmailChange}
                edit={edit}
                password={password}
                handlePasswordChange={handlePasswordChange}
                passwordConfirmation={passwordConfirmation}
                handlePasswordConfirmationChange={handlePasswordConfirmationChange}
                setCuponsModalShow={setCuponsModalShow}
                setPlanKind={setPlanKind}
                planValue={planValue}
                handlePlanValueChange={handlePlanValueChange}
                planKind={planKind}
                planTrial={planTrial}
                handlePlanTrialChange={handlePlanTrialChange}
                kindOfCompany={kindOfCompany}
                setShowAtCompaniesListAppRegister={setShowAtCompaniesListAppRegister}
                showAtCompaniesListAppRegister={showAtCompaniesListAppRegister}
                setViewByAssociatedCompanies={setViewByAssociatedCompanies}
                viewByAssociatedCompanies={viewByAssociatedCompanies}
              />
            </OpacityAnimation>
          </Col>
        </Row>

      {permission !== 'support' && (
        <ButtonContainer>
          {!edit && (
            <Button
              disabled={!isFormValid}
              onClick={createCompany}
              tooltip={(renderArray(requiredFieldsErrorsLabel) ?? '')}
              tooltipExplanation='Campos obrigatórios não preenchidos:'
            >
              {`Adicionar empresa ${fantasyName ?? ''}`}
            </Button>
          )}

          {edit && (
            <Button
              disabled={!isFormValid}
              onClick={() => updateCompany()}
              tooltip={(renderArray(requiredFieldsErrorsLabel) ?? '')}
              tooltipExplanation='Campos obrigatórios não preenchidos:'
            >
              {`Editar empresa ${fantasyName ?? ''}`}
            </Button>
          )}
        </ButtonContainer>
      )}
      </Container>

      <AssociatedCompaniesModal
        associatedCompaniesModalShow={associatedCompaniesModalShow}
        setAssociatedCompaniesModalShow={setAssociatedCompaniesModalShow}
        associatedCompanies={associatedCompanies}
        handleFileUpload={(e: ChangeEvent<HTMLInputElement>) => handleFileUpload(e)}
        addOneAssociatedCompany={addOneAssociatedCompany}
        removeAssociatedCompany={removeAssociatedCompany}
        companyBeingAdded={companyBeingAdded}
        setCompanyBeingAdded={setCompanyBeingAdded}
      />

      <CuponsModal
        cuponsModalShow={cuponsModalShow}
        setCuponsModalShow={setCuponsModalShow}
        cupons={cupons}
        addCupom={addCupom}
        removeCupom={removeCupom}
        cupomPlanKind={cupomPlanKind}
        cupomPlanValue={cupomPlanValue}
        cupomPlanTrial={cupomPlanTrial}
        cupomName={cupomName}
        handleCupomPlanValueChange={handleCupomPlanValueChange}
        handleCupomPlanTrialChange={handleCupomPlanTrialChange}
        handleCupomNameChange={handleCupomNameChange}
        setCupomPlanKind={setCupomPlanKind}
        getErrorMessageByFieldNameCupomForm={getErrorMessageByFieldNameCupomForm}
        isCupomFormValid={isCupomFormValid}
        edit={edit}
        cuponsOptions={cuponsOptions}
        associatedCuponsIds={associatedCuponsIds}
        setAssociatedCuponsIds={setAssociatedCuponsIds}
        expires={expires}
        setExpires={setExpires}
        handleExpirationDateChange={handleExpirationDateChange}
        expirationDate={expirationDate}
        willLimitUsage={willLimitUsage}
        setWillLimitUsage={setWillLimitUsage}
        handleUseLimitChange={handleUseLimitChange}
        useLimit={useLimit}
        considerTestUntilTrialDays={considerTestUntilTrialDays}
        setConsiderTestUntilTrialDays={setConsiderTestUntilTrialDays}
      />
    </>
  );
}
