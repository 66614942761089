import { isPast } from 'date-fns';
import { Col, Container, Row } from 'react-bootstrap';
import activate from '../../../../assets/images/icons/activate.svg';
import edit from '../../../../assets/images/icons/edit.svg';
import suspend from '../../../../assets/images/icons/suspend.svg';
import trash from '../../../../assets/images/icons/trash.svg';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import { useAppContext } from '../../../../contexts/auth';
import floatToCurrency from '../../../../utils/floatToCurrency';
import utcToRegularDate from '../../../../utils/utcToRegularDate';
import { CupomFromApiObject, HandleOpenChangeStatusModalInterface } from '../../types';
import {
  Card,
} from './styles';

interface ListInterface {
  filteredList: CupomFromApiObject[];
  handleOpenEditModal: (cupomObject: CupomFromApiObject) => void;
  handleOpenDeleteModal: (cupomName: string, cupomId: string, cupomPlanId?: number | null) => void;
  handleOpenChangeStatusModal: (cupomChangingStatusObject: HandleOpenChangeStatusModalInterface) => void;
}

export default function List({
  filteredList,
  handleOpenEditModal,
  handleOpenDeleteModal,
  handleOpenChangeStatusModal,
}: ListInterface) {
  const cupomKindLiterals: { [key: string]: JSX.Element } = {
    free: <small className='green'>Acesso Grátis</small>,
    discount: <small>Desconto</small>,
    discount_no_trial: <small>Desconto (Sem dias grátis)</small>,
    trial: <small className='orange'>Período grátis</small>,
    trial_discount: <small className='gray'>Período + desconto</small>,
    trial_no_subscription: <small className='purple'>Período grátis (sem cobrança)</small>,
  }

  const { user: { permission } } = useAppContext();

  return (
    <Container>
      <Row xs={1} md={2} lg={2}>
        {filteredList?.map((cupom) => {
          const doesCupomHasExpirationDate = cupom.expiresAt;
          const isCupomExpired = cupom.expiresAt ? isPast(cupom.expiresAt) : false;
          return (
            <Col key={cupom.id}>
            <OpacityAnimation delay={0.1}>
              <Card>
                <div className="info">
                  <div className="card-title">
                    <strong>{cupom.name}</strong>
                    {cupomKindLiterals[cupom.planKind]}
                  </div>
                  <span>
                    Empresa: {cupom.company?.nome_fantasia || 'Cupom geral'}
                  </span>
                  <span>
                    Valor: {floatToCurrency(cupom.planValue) || 'Não definido'}
                  </span>
                  <span>
                    Período (dias): {cupom.planTrialDays || 'Não definido'}
                  </span>
                  <span>
                    {doesCupomHasExpirationDate ? (
                      isCupomExpired ? (<strong>Cupom vencido</strong>) : (
                        `Vence no dia ${utcToRegularDate(cupom.expiresAt)}`
                      )
                    )
                    : 'Cupom não tem vencimento'}
                  </span>
                  <span>
                    {cupom.useLimit ? (
                      `Limite de uso: ${cupom.useLimit}`
                    )
                    : 'Cupom não tem limite de uso'}
                  </span>
                </div>
                <div className="actions">
                  {permission !== 'support' && (
                    <button
                        type="button"
                        onClick={() => handleOpenChangeStatusModal({
                          name: cupom.name,
                          id: cupom.id,
                          active: cupom.active,
                        })}
                      >
                        {cupom.active ? (
                          <img src={suspend} alt="suspend" title="Inativar Cupom" />
                        ) : (
                          <img src={activate} alt="trash" title="Reativar Cupom" />
                        )}
                      </button>
                  )}
                  <button
                      type="button"
                      onClick={() => handleOpenEditModal(cupom)}
                    >
                      <img src={edit} alt="" title="Editar cupom" className="primaryColor" />
                  </button>
                  {permission !== 'support' && (
                    <button
                        type="button"
                        onClick={() => handleOpenDeleteModal(cupom.name, cupom.id, cupom.plan_id)}
                      >
                        <img src={trash} alt="" title="Remover cupom" />
                    </button>
                  )}
                </div>
              </Card>
            </OpacityAnimation>
          </Col>
          )
        })}
      </Row>
    </Container>
  );
}
