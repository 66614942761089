import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import splitArray from '../../../../../utils/splitArray';
import { UsersList } from '../../interfaces/UsersList';

type SelectedCodStatusType = 0 | 1 | 2 | 3;
type SplitedUsersArray = Array<UsersList[]> | [[]];

interface UsePageSearchesInterface {
  fullUsers: UsersList[];
  usersSplited: Array<UsersList[]> | UsersList[];
  currentPage: number;
  filteredUsers: UsersList[] | [];
  setUsersSplited: Dispatch<SetStateAction<Array<UsersList[]>> | [[]]>;
  setFilteredUsers: Dispatch<SetStateAction<UsersList[] | []>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

export default function usePageSearches({
  fullUsers,
  usersSplited,
  currentPage,
  filteredUsers,
  setUsersSplited,
  setFilteredUsers,
  setCurrentPage,
}: UsePageSearchesInterface) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCodStatus, setSelectedCodStatus] = useState<SelectedCodStatusType>(0);
  const [selectedCompany, setSelectedCompany] = useState<{ value: string | null, label: string }>({ value: null, label: 'Visão Geral' });
  const [selectedStatus, setSelectedStatus] = useState({ value: '', label: 'Filtrar por status' });

  const statusOptions = useMemo(() => (
    [
      { value: '', label: 'Todos' },
      { value: 'Cadastrado', label: 'Cadastrados' },
      { value: 'Ativa', label: 'Ativos' },
      { value: 'Cancelada', label: 'Cancelados' },
      { value: 'Período de teste', label: 'Período de teste' },
      { value: 'Suspensa', label: 'Suspensos' },
    ]
  ), []);


  const filterUsers = useCallback(() => {
    const filtered = fullUsers?.filter((collab) => (
      (String(collab.id).startsWith(String(searchTerm)))
      && (collab.codStatus === selectedCodStatus || selectedCodStatus === 0)
      && (collab.associatedCompany === selectedCompany.value || !selectedCompany.value)
      && (collab.userStatus === selectedStatus.value || selectedStatus.value === '')
    ));
    const usersSplitedByFilters: SplitedUsersArray = splitArray(filtered);
    setUsersSplited(usersSplitedByFilters);
    setFilteredUsers(usersSplitedByFilters[0]);
  }, [fullUsers, searchTerm, selectedCodStatus, selectedCompany.value, selectedStatus.value, setFilteredUsers, setUsersSplited]);

  useEffect(() => {
    filterUsers();
  }, [filterUsers]);

  const handleChangeSearchTerm = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }, []);

  const handleChangeStatusFilter = useCallback((codStatus: SelectedCodStatusType) => {
    setSelectedCodStatus(codStatus);
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
    setFilteredUsers(usersSplited[page] as UsersList[] | []);
  }, [setCurrentPage, setFilteredUsers, usersSplited]);

  const handleCompanyChange = useCallback((company: { value: string | null, label: string }) => {
    setSelectedCompany(company);
  }, []);

  const handleStatusChange = useCallback((event: { value: string, label: string }) => {
    setSelectedStatus(event);
  }, []);

  return {
    searchTerm,
    selectedCodStatus,
    setSelectedCodStatus,
    filteredUsers,
    handleChangeSearchTerm,
    handleChangeStatusFilter,
    usersSplited,
    currentPage,
    handlePageChange,
    handleCompanyChange,
    selectedCompany,
    statusOptions,
    selectedStatus,
    handleStatusChange,
  };
}
