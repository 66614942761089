import { pathName } from '../pathName';
import { ReqBodyType } from './types/reqBody';
import HttpClient from './utils/HttpClient';

interface CompaniesServiceInterface {
  permission?: string;
  reqBody?: ReqBodyType;
  id?: string;
  ativo?: boolean;
  companyName?: string;
  companyId?: string;
  token: string;
  associatedCompany?: string | null
}

interface UsersServiceInterfae {
  id?: string,
  companyName?: string,
  companyId?: string,
  page?: number,
  reqBody?: ReqBodyType,
  token: string,
}

class CompaniesService {
  private readonly httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient(pathName);
  }

  getCompaniesList = async ({
    token,
  }: CompaniesServiceInterface) => {
    return this.httpClient.get({
      path: '/companies',
      token,
    });
  }

  createCompany = async ({
    reqBody,
    token,
  }: CompaniesServiceInterface) => {
    return this.httpClient.post({
      path: '/companies',
      token,
      reqBody,
    });
  }

  getCompanyDetails = async ({
    companyId,
    token,
  }: CompaniesServiceInterface) => {
    return this.httpClient.get({
      path: `/companies/${companyId}`,
      token,
    });
  }

  updateCompany = async ({
    companyId,
    reqBody,
    token,
  }: CompaniesServiceInterface) => {
    return this.httpClient.put({
      path: `/companies/${companyId}`,
      token,
      reqBody,
    });
  }

  changeCompanyStatus = async ({
    ativo,
    id,
    token,
  }: CompaniesServiceInterface) => {
    return this.httpClient.patch({
      path: `/companies/${id}?ativo=${ativo}`,
      token,
    });
  }

  getCollaboratorsInfo = async ({
    companyId,
    associatedCompany,
    token,
  }: CompaniesServiceInterface) => {
    return this.httpClient.get({
      path: associatedCompany ? `/companies/dashboard-collaborators/${companyId}?associatedCompany=${encodeURIComponent(associatedCompany)}` : `/companies/dashboard-collaborators/${companyId}`,
      token,
    });
  }

  getKidsInfo = async ({
    companyId,
    associatedCompany,
    token,
  }: CompaniesServiceInterface) => {
    return this.httpClient.get({
      path: associatedCompany ? `/companies/kids/${companyId}?associatedCompany=${encodeURIComponent(associatedCompany)}` : `/companies/kids/${companyId}`,
      token,
    });
  }

  getRatings = async ({
    companyId,
    associatedCompany,
    token,
  }: CompaniesServiceInterface) => {
    return this.httpClient.get({
      path: associatedCompany ? `/companies/ratings/${companyId}?associatedCompany=${encodeURIComponent(associatedCompany)}` : `/companies/ratings/${companyId}`,
      token,
    });
  }

  getRanking = async ({
    companyId,
    associatedCompany,
    token,
  }: CompaniesServiceInterface) => {
    return this.httpClient.get({
      path: associatedCompany ? `/companies/ranking/${companyId}?associatedCompany=${encodeURIComponent(associatedCompany)}` : `/companies/ranking/${companyId}`,
      token,
    });
  }
//
  listUsers = async({
    companyId,
    token,
  }: UsersServiceInterfae) => {
    return this.httpClient.get({
      path: `/companies-collaborators/collaborators/${companyId}`,
      token,
    });
  };

  createMultiUsers = async({
    companyId,
    reqBody,
    token,
  }: UsersServiceInterfae) => {
    return this.httpClient.post({
      path: `/companies-collaborators/collaborators/${companyId}`,
      reqBody,
      token,
    });
  };

  deleteUser = async({
    companyId,
    reqBody,
    token,
  }: UsersServiceInterfae) => {
    return this.httpClient.delete({
      path: `/companies-collaborators/collaborators/${companyId}`,
      reqBody,
      token,
    });
  };

  getCanceleds = async({
    companyId,
    token,
  }: UsersServiceInterfae) => {
    return this.httpClient.get({
      path: `/canceleds/${companyId}`,
      token,
    });
  };
}

export default new CompaniesService();
