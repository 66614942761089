import { ChangeEventHandler } from 'react';
import Select from 'react-select';
import { CustomStyle } from '../../../../../components/CustomSelectStyle';
import { CustomStyle as CustomStyleDarkTheme } from '../../../../../components/CustomSelectStyleDarkTheme';
import FilterRadioButton from '../../../../../components/FilterRadioButtons';
import { FilterRadioButtonsContainer } from '../../../../../components/FilterRadioButtonsContainer';
import OpacityAnimation from '../../../../../components/OpacityAnimation';
import useThemeContext from '../../../../../contexts/theme';
import {
  InputSearchContainer, SearchContainer,
} from './styles';


type SelectedCodStatusType = 0 | 1 | 2 | 3;

interface SearchInterface {
  searchTerm: string;
  onChangeSearchTerm: ChangeEventHandler<HTMLInputElement>;
  selectedFilter: SelectedCodStatusType;
  onChangeSelectedStatus: (_selectedCodStatus: SelectedCodStatusType) => void;
  isReadOnly: boolean;
  viewByAssociatedCompanies: boolean;
  associatedCompanies: string[];
  handleCompanyChange: (company: { value: string | null, label: string }) => void;
  selectedCompany: { value: string | null, label: string }
  statusOptions: { value: string, label: string }[];
  selectedStatus: { value: string, label: string };
  handleStatusChange: (status: { value: string, label: string }) => void;
}

export default function Search({
  searchTerm,
  onChangeSearchTerm,
  selectedFilter,
  onChangeSelectedStatus,
  isReadOnly,
  viewByAssociatedCompanies,
  associatedCompanies,
  selectedCompany,
  handleCompanyChange,
  statusOptions,
  selectedStatus,
  handleStatusChange,
}: SearchInterface) {
  const { selectedTheme } = useThemeContext();

  const companyOptionsMapped = associatedCompanies?.map((cp: string) => ({ value: cp, label: cp }));

  const options = [
    { value: null, label: 'Visão Geral '},
    ...companyOptionsMapped,
  ];

  return (
    <OpacityAnimation delay={0.1}>
      {viewByAssociatedCompanies && (
        <Select
          value={selectedCompany}
          options={options}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          onChange={(company) => handleCompanyChange(company!)}
          placeholder="Visão Geral"
          styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
          classNamePrefix="react-select"
          className="react-select-container-as-filter"
        />
      )}

      <SearchContainer>
        <InputSearchContainer>
          <input
            value={searchTerm}
            type="text"
            placeholder="Pesquisar colaborador"
            onChange={onChangeSearchTerm}
          />
        </InputSearchContainer>
        {!isReadOnly && (
          <FilterRadioButtonsContainer>
            <FilterRadioButton
              onClick={() => onChangeSelectedStatus(0)}
              selected={selectedFilter === 0}
            >
              Todos
            </FilterRadioButton>
            <FilterRadioButton
              onClick={() => onChangeSelectedStatus(1)}
              selected={selectedFilter === 1}
            >
              Ativos
            </FilterRadioButton>
            <FilterRadioButton
              onClick={() => onChangeSelectedStatus(2)}
              selected={selectedFilter === 2}
            >
              Suspensos
            </FilterRadioButton>
            <FilterRadioButton
              onClick={() => onChangeSelectedStatus(3)}
              selected={selectedFilter === 3}
            >
              Registrados
            </FilterRadioButton>
          </FilterRadioButtonsContainer>
        )}

        {isReadOnly && (
          <Select
          value={{ value: selectedStatus.value, label: selectedStatus.label === 'Todos' ? 'Filtrar por status' : selectedStatus.label }}
          options={statusOptions}
          onChange={(status) => handleStatusChange(status as { value: string, label: string })}
          placeholder="Filtrar por status"
          styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
          // menuPortalTarget={document.body}
          classNamePrefix="react-select"
          className="react-select-container-as-filter"
        />
        )}

      </SearchContainer>
    </OpacityAnimation>
  );
}
