import { Link } from 'react-router-dom';
import OpacityAnimation from '../../../../../components/OpacityAnimation';
import Pagination from '../../../../../components/Pagination';
import { useAppContext } from '../../../../../contexts/auth';
import { Header } from './styles';

interface ListHeaderInterface {
  doesListExists: boolean;
  hasError: boolean;
  filteredListLength: number;
  singularLabel: string;
  pluralLabel: string;
  filePathDownload: string;
  showDownloadModal: () => void;
  isManualCreateAtModal?: boolean;
  handleImportShow: () => void;
  handleCreateModalShow: () => void;
  onPageChange: (page: number) => void;
  pagesQuantity?: number;
  currentPage: number;
  isReadOnly: boolean;
  downloadUsageRelatory: () => Promise<void>;
  downloadCanceledsRelatory: () => Promise<void>;
}

export default function ListHeader({
  doesListExists,
  hasError,
  filteredListLength,
  singularLabel,
  pluralLabel,
  filePathDownload,
  showDownloadModal,
  isManualCreateAtModal = false,
  handleImportShow,
  handleCreateModalShow,
  onPageChange,
  pagesQuantity = 0,
  currentPage,
  isReadOnly,
  downloadUsageRelatory,
  downloadCanceledsRelatory,
}: ListHeaderInterface) {
  const { user: { permission } } = useAppContext();

  return (
    <OpacityAnimation delay={0.1}>
      <Header doesListExists={doesListExists} apiFetched={!hasError}>
        {(doesListExists) && (
          <div className="leftAlignment">
            <strong>
              {filteredListLength || 0}
              {' '}
              {filteredListLength === 1 ? singularLabel : pluralLabel}
            </strong>
            <Pagination
              pagesQuantity={pagesQuantity}
              handlePageChange={onPageChange}
              currentPage={currentPage}
            />
          </div>
        )}
        {!isReadOnly && (
          <div className="actionButtons">
            {permission === 'super' && (
              <>
                <Link to={filePathDownload} target="_blank" download>
                  Exemplo CSV
                </Link>

                <button type="button" onClick={handleImportShow}>Importar CSV</button>
              </>
            )}
            {filteredListLength > 0 && (
              <>
                <button type="button" onClick={showDownloadModal}>Download</button>
                <button type="button" onClick={downloadUsageRelatory}>Relatório de Uso</button>
              </>
            )}
            {(isManualCreateAtModal && permission === 'super') && (
              <button type="button" onClick={handleCreateModalShow}>Novo beneficiário</button>
            )}
          </div>
        )}

        <button type="button" onClick={downloadCanceledsRelatory}>Relatório Cancelamentos</button>
      </Header>
    </OpacityAnimation>
  );
}
