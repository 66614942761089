import { AnimatePresence } from 'framer-motion';
import {
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

// // pages
import NotFound from '../pages/NotFound';

import Activities from '../pages/Activities';
import EditActivity from '../pages/Activities/EditActivity';
import NewActivity from '../pages/Activities/NewActivity';

import Users from '../pages/Users';

import Categories from '../pages/Activities/Categories';
import ActivityPedagogicalProgram from '../pages/Activities/PedagogicalProgram';
import ActivityProgram from '../pages/Activities/Program';
import ChangePassword from '../pages/ChangePassword';
import Companies from '../pages/Companies';
import Collaborators from '../pages/Companies/Collaborators';
import EditCompany from '../pages/Companies/EditCompany';
import Cupons from '../pages/Cupons';
import Dashboard from '../pages/Dashboard';
import Forum from '../pages/Forum';
import ForumInternal from '../pages/ForumInternal';
import GivenClasses from '../pages/GivenClasses';
import EditGivenClass from '../pages/GivenClasses/EditGivenClass';
import NewGivenClass from '../pages/GivenClasses/NewGivenClass';
import Schedules from '../pages/Schedules';
import Subscribers from '../pages/Subscribers';
import EditUser from '../pages/Users/EditUser';

export default function SupportRoute() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes
        location={location}
        key={location.pathname}
      >
        <Route path="/" element={<Dashboard />} />
        <Route path="/subscribers" element={<Subscribers />} />
        <Route path="/forum" element={<Forum />} />
        <Route path="/forumInternal" element={<ForumInternal />} />

        <Route path="/activities" element={<Activities />} />
        <Route path="/activities/:id" element={<EditActivity />} />
        <Route path="/activities/:id/program" element={<ActivityProgram />} />
        <Route path="/activities/:id/pedagogicalProgram" element={<ActivityPedagogicalProgram />} />
        <Route path="/activities/new" element={<NewActivity />} />
        <Route path="/activities/categories" element={<Categories />} />

        <Route path="/givenClasses" element={<GivenClasses />} />
        <Route path="/givenClasses/new" element={<NewGivenClass />} />
        <Route path="/givenClasses/:givenClassId" element={<EditGivenClass />} />

        <Route path="/cupons" element={<Cupons />} />

        <Route path="/companies" element={<Companies />} />
        <Route path="/companies/:id" element={<EditCompany />} />
        <Route path="/companies/collaborators/:companyId/:companyName" element={<Collaborators />} />

        <Route path="/users" element={<Users />} />
        <Route path="/users/:id" element={<EditUser />} />

        <Route path="/schedules" element={<Schedules />} />

        <Route path="/changePassword" element={<ChangePassword />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </AnimatePresence>
  );
}
