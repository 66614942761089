/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useApiCall from '../../../../../hooks/useApiCall';
import companiesService from '../../../../../services/companiesService';
import ExportXlsx from '../../../../../utils/ExportXlsx';

type CanceledType = {
  id: string;
  company?: string | null;
  companyId?: string | null;
  data_ativacao: string;
  data_cancelamento: string;
  duracao: string;
}

type CanceledsFromApiResponse = {
  success: boolean;
  canceledsOfCompany?: CanceledType[];
  error?: string;
}

export default function useDownloadCanceledsRelatory() {
  const { apiCall } = useApiCall();
  const navigate = useNavigate();

  const { companyId, companyName } = useParams();

  const today = new Date().toJSON().slice(0, 10);
  const day = today.split('-')[2];
  const month = today.split('-')[1];
  const year = today.split('-')[0];
  const currentDate = `${day}-${month}-${year}`;

  const downloadCanceledsRelatory = useCallback(async () => {
    if (!companyId) {
      toast.error('Não identificamos a empresa através da URL. Por favor, tente novamente.');
      navigate('/companies');
      return;
    }

    await toast.promise(apiCall({
      apiToCall: companiesService.getCanceleds,
      queryParams: { companyId },
      actionAfterResponse: (apiResponse: CanceledsFromApiResponse) => {
        if (!apiResponse.success) {
          toast.error(`Não foi possível baixar o relatório de cancelamentos (${apiResponse.error})`);
          return;
        }

        if (apiResponse.canceledsOfCompany?.length === 0) {
          toast.info('Não há cancelamentos para essa empresa');
          return;
        }

        const canceleds = apiResponse.canceledsOfCompany!;
        ExportXlsx({
          data: canceleds.map((canceled) => ({
            'Data de Ativação': canceled.data_ativacao,
            'Data de Cancelamento': canceled.data_cancelamento,
            'Duração': canceled.duracao,
          })),
          filename: `Relatório de cancelamentos KP - ${companyName} - ${currentDate}`,
        });
      },
      catchMessage: 'Não foi possível baixar o relatório de cancelamentos,'
    }), {
      pending: 'Sua planilha está sendo baixada',
      success: 'Sua planilha foi baixada!',
      error: 'Não foi possível baixar sua planilha',
    });
  }, [companyId, apiCall, navigate, companyName, currentDate]);

  return {
    downloadCanceledsRelatory,
  };
}
