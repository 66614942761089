import { pathName } from '../pathName';
import { ReqBodyType } from './types/reqBody';
import HttpClient from './utils/HttpClient';

interface UsersServiceInterface {
  page?: string;
  id?: string;
  reqBody?: ReqBodyType;
  target?: string;
  pushMessage?: string;
  token: string;
  userId?: string;
}

class UsersService {
  private readonly httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient(pathName);
  }

  getUsersList = async ({
    token,
  }: UsersServiceInterface) => {
    return this.httpClient.get({
      path: '/users',
      token,
    });
  }

  getUserDetails = async ({
    id,
    token,
  }: UsersServiceInterface) => {
    return this.httpClient.get({
      path: `/users/${id}`,
      token,
    });
  }

  updateUser = async ({
    userId,
    reqBody,
    token,
  }: UsersServiceInterface) => {
    return this.httpClient.put({
      path: `/users/${userId}`,
      reqBody,
      token,
    });
  }

  deleteUser = async ({
    id,
    token,
  }: UsersServiceInterface) => {
    return this.httpClient.delete({
      path: `/users/${id}`,
      token,
    });
  }

  getUsersKidsAverage = async ({
    token,
  }: UsersServiceInterface) => {
    return this.httpClient.get({
      path: '/users-kids-qtd',
      token,
    });
  }

  getUsersRegions = async ({
    token,
  }: UsersServiceInterface) => {
    return this.httpClient.get({
      path: '/users-cities',
      token,
    });
  }

  getUsersRatings = async ({
    token,
  }: UsersServiceInterface) => {
    return this.httpClient.get({
      path: '/users-nps',
      token,
    });
  }

  getUsersGenders = async ({
    token,
  }: UsersServiceInterface) => {
    return this.httpClient.get({
      path: '/users-genres',
      token,
    });
  }

  getUsersAges = async ({
    token,
  }: UsersServiceInterface) => {
    return this.httpClient.get({
      path: '/users-age',
      token,
    });
  }

  getUsersSo = async ({
    token,
  }: UsersServiceInterface) => {
    return this.httpClient.get({
      path: '/users-so',
      token,
    });
  }

  getActiveUsersByPeriod = async ({
    token,
  }: UsersServiceInterface) => {
    return this.httpClient.get({
      path: '/users-active-period',
      token,
    });
  }

  getKidsAges = async ({
    token,
  }: UsersServiceInterface) => {
    return this.httpClient.get({
      path: '/users-kids-age',
      token,
    });
  }

  sendPushNotification = async ({
    reqBody,
    token,
  }: UsersServiceInterface) => {
    return this.httpClient.post({
      path: `/users/sendNotification`,
      reqBody,
      token,
    });
  }

  sendEmail = async ({
    reqBody,
    token,
  }: UsersServiceInterface) => {
    return this.httpClient.post({
      path: `/users/sendEmail`,
      reqBody,
      token,
      contentType: 'multipart/form-data',
    });
  }

  sendWhatsapp = async ({
    reqBody,
    token,
  }: UsersServiceInterface) => {
    return this.httpClient.post({
      path: `/users/sendWhatsapp`,
      reqBody,
      token,
      contentType: 'multipart/form-data',
    });
  }
}

export default new UsersService();
