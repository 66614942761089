import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../hooks/useApiCall';
import usersService from '../../services/usersService';
import removeDuplicates from '../../utils/removeDuplicates';

type SelectedTargetType = {
  label: string;
  value: string;
};

type SubscriberFromApi = {
  assinatura_status: string;
  celular: string;
  data_ativacao: string;
  email: string;
  empresa: string;
  nome: string;
  _id: string;
  company?: {
    id?: string;
    nome?: string;
  };
  cupom_utilizado?: {
    code?: string;
  }
};

type UserFromApi = {
  email: string;
  nome: string;
  assinatura_status: 'Ativa' | 'Cancelada';
  data_ativacao?: string;
}

export default function useSendWhatsapp() {
  const [isLoading, setIsLoading] = useState(false);
  const [whatsModalShow, setWhatsModalShow] = useState(false);
  const [selectedWhatsTarget, setSelectedWhatsTarget] = useState<SelectedTargetType>({} as SelectedTargetType);
  const [whatsMessage, setWhatsMessage] = useState('');
  const [willSendWhats, setWillSendWhats] = useState(false);
  const [selectedAgeRangeWhats, setSelectedAgeRangeWhats] = useState([0, 13]);
  // const [choosedAgeRangeWhats, setChoosedAgeRangeWhats] = useState([0, 13]);
  const [whatsMessageType, setWhatsMessageType] = useState('text');

  const [image, setImage] = useState<File | string | undefined>('');
  const [imageWhatsFileName, setImageWhatsFileName] = useState('');
  const [video, setVideo] = useState<File | string | undefined>('');
  const [videoWhatsFileName, setVideoWhatsFileName] = useState('');
  const [pdf, setPdf] = useState<File | string | undefined>('');
  const [pdfWhatsFileName, setPdfWhatsFileName] = useState('');

  const [targetOptionsWhats, setTargetOptionsWhats] = useState([
    { value: 'Teste', label: 'Teste' },
    { value: 'Assinantes', label: 'Assinantes' },
    { value: 'Cadastrados', label: 'Cadastrados' },
    { value: 'Todos', label: 'Todos' },
  ]);

  const { apiCall } = useApiCall();

  function handleWhatsMessageChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setWhatsMessage(event.target.value);
  }

  // const handleAgeSelectionWhats = useCallback((age: number[]) => {
  //   setChoosedAgeRangeWhats(age);
  // }, []);

  function handleImageWhatsUpload(event: ChangeEvent<HTMLInputElement>) {
    const { files } = event.target;
    if (!files) {
      toast.error('Não foi possível subir esse arquivo. Por favor, tente novamente')
      return;
    }
    setImage(files[0])
    setImageWhatsFileName(files[0].name)
  }

  function removeWhatsImage() {
    setImageWhatsFileName('');
    setImage(undefined);
  }

  function handleVideoWhatsUpload(event: ChangeEvent<HTMLInputElement>) {
    const { files } = event.target;
    if (!files) {
      toast.error('Não foi possível subir esse arquivo. Por favor, tente novamente')
      return;
    }
    // Se o video for maior do que 100mb, não permitir o upload
    if (files[0].size > 100000000) {
      toast.error('O vídeo não pode ser maior do que 100mb');
      return;
    }
    setVideo(files[0])
    setVideoWhatsFileName(files[0].name)
  }

  function removeWhatsVideo() {
    setVideoWhatsFileName('');
    setVideo(undefined);
  }

  function handlePdfWhatsUpload(event: ChangeEvent<HTMLInputElement>) {
    const { files } = event.target;
    if (!files) {
      toast.error('Não foi possível subir esse arquivo. Por favor, tente novamente')
      return;
    }
    setPdf(files[0])
    setPdfWhatsFileName(files[0].name)
  }

  function removeWhatsPdf() {
    setPdfWhatsFileName('');
    setPdf(undefined);
  }

  const loadCompaniesAndUsers = useCallback(async () => {
    await apiCall({
      apiToCall: usersService.getUsersList,
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.error) {
          toast.error(`Não foi possível carregar todas as opções de envio de notificações push (${apiResponse.error})`, {
            toastId: 'getSubscribersFail',
          });
          setIsLoading(false);
          return;
        }
        const subscribersList: SubscriberFromApi[] = apiResponse.list;
        const companiesFromSubscribers = subscribersList?.map((subscriber) => (
          {value: subscriber.company?.id || null, label: subscriber.company?.nome || 'B2C'}
        ));

        const companies = removeDuplicates(companiesFromSubscribers);
        const companiesFiltered = companies.filter((company) => company.label !== 'JaSouKiddle');

        const usersList: UserFromApi[] = apiResponse.list;

        const mappedUsersToTargetOptions = usersList.map((usr) => ({
          value: usr.email, label: usr.nome
        }))

        setTargetOptionsWhats((prevState: { value: string, label: string }[]) => (
          removeDuplicates([...prevState, ...companiesFiltered, ...mappedUsersToTargetOptions])
        ));
      },
      catchMessage: 'Não foi possível carregar as empresas para envio de notificações push'
    })
  }, [apiCall]);

  const sendWhats = useCallback(async () => {
    await apiCall({
      apiToCall: usersService.sendWhatsapp,
      reqBody: [
        { key: 'message', value: whatsMessage },
        { key: 'target', value: selectedWhatsTarget.value },
        // { key: 'kidsAge', value: (choosedAgeRangeWhats[1] === 13 && choosedAgeRangeWhats[0] === 0) ? null : JSON.stringify(choosedAgeRangeWhats), },
        { key: 'pdf', value: whatsMessageType === 'pdf' ? pdf : null },
        { key: 'image', value: whatsMessageType === 'image' ? image : null },
        { key: 'video', value: whatsMessageType === 'video' ? video : null },
      ],
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => {
        setIsLoading(false);
        setWillSendWhats(false);
      },
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error('Não foi possível enviar as mensagens. Por favor, tente novamente');
          setWillSendWhats(false);
          return;
        }
        toast.success('As mensagens foram enviadas com sucesso');
        setWillSendWhats(false);
      },
      catchMessage: 'Não foi possível enviar as mensagens'
    })
  }, [apiCall, image, pdf, selectedWhatsTarget.value, video, whatsMessage, whatsMessageType]);

  useEffect(() => {
    if (targetOptionsWhats.length <= 4 && whatsModalShow) {
      loadCompaniesAndUsers();
    }
  }, [loadCompaniesAndUsers, targetOptionsWhats.length, whatsModalShow])

  return {
    whatsModalShow,
    setWhatsModalShow,
    setWillSendWhats,
    willSendWhats,
    sendWhats,
    selectedTargetWhats: selectedWhatsTarget,
    whatsMessage,
    whatsMessageType,
    setWhatsMessageType,
    targetWhatsOptions: targetOptionsWhats,
    setSelectedWhatsTarget,
    selectedWhatsTarget,
    selectedAgeRangeWhats,
    // handleAgeSelectionWhats,
    setSelectedAgeRangeWhats,
    handleWhatsMessageChange,
    handleImageWhatsUpload,
    imageWhatsFileName,
    removeWhatsImage,
    handlePdfWhatsUpload,
    pdfWhatsFileName,
    removeWhatsPdf,
    isWhatsLoading: isLoading,
    handleVideoWhatsUpload,
    videoWhatsFileName,
    removeWhatsVideo,
  };
}
