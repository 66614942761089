/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useApiCall from '../../../../../../hooks/useApiCall';
import kidsService from '../../../../../../services/kidsService';
import formatDate from '../../../../../../utils/formatDate';
import formatDateToUsa from '../../../../../../utils/formatDateToUsa';
import isDateBeforeToday from '../../../../../../utils/isDateBeforeToday';
import { UserChildType } from '../../../../types';
import { ChildrenInfoBeingShownType } from '../../types';

interface UseUsersChildrensI {
  loadForm: () => Promise<void>;
  childrens: UserChildType[];
  setChildrens: Dispatch<SetStateAction<UserChildType[]>>
}

export default function useUsersChildrens({ loadForm, childrens }: UseUsersChildrensI) {
  const [isLoadingAfterChildrenAction, setIsLoadingAfterChildrenAction] = useState(false);
  const [addChildrenModalShow, setAddChildrenModalShow] = useState(false);
  const [childrenDetailsModalShow, setChildrenDetailsModalShow] = useState(false);
  const [childrenInfoBeingShow, setChildrenInfoBeingShow] = useState<ChildrenInfoBeingShownType>({} as ChildrenInfoBeingShownType);

  const [childrenNameBeingAdded, setChildrenNameBeingAdded] = useState('');
  const [childrenObservationBeingAdded, setChildrenObservationBeingAdded] = useState('');
  const [childrenBirthDateBeingAdded, setChildrenBirthDateBeingAdded] = useState('');
  const [childrenPeriodBeingAdded, setChildrenPeriodBeingAdded] = useState<{ value: string, label: string }[]>([]);
  const [childrenInterestsBeingAdded, setChildrenInterestsBeingAdded] = useState<{ value: string, label: string }[]>([]);
  const [childrenneedsInterpreterBeingAdded, setChildrenneedsInterpreterBeingAdded] = useState<boolean | undefined>(false);

  const [childrenNameBeingEditted, setChildrenNameBeingEditted] = useState('');
  const [childrenObservationBeingEditted, setChildrenObservationBeingEditted] = useState('');
  const [childrenBirthDateBeingEditted, setChildrenBirthDateBeingEditted] = useState('');
  const [childrenPeriodBeingEditted, setChildrenPeriodBeingEditted] = useState<{ value: string, label: string }[]>([]);
  const [childrenInterestsBeingEditted, setChildrenInterestsBeingEditted] = useState<{ value: string, label: string }[]>([]);
  const [childrenneedsInterpreterBeingEditted, setChildrenneedsInterpreterBeingEditted] = useState<boolean | undefined>(false);

  const [childrenBeingRemoved, setChildrenBeingRemoved] = useState('');
  const [childrenRemoveModalShow, setChildrenRemoveModalShow] = useState(false);

  const { apiCall } = useApiCall();
  const { id } = useParams();

  function handleOpenChildrenDetailsModal({
    name,
    birthDate,
    preferredPeriods,
    preferredCategories,
    id,
    observation,
    needsInterpreter,
  }: ChildrenInfoBeingShownType) {
    setChildrenInfoBeingShow({
      name,
      birthDate,
      preferredPeriods,
      preferredCategories,
      id,
      observation,
      needsInterpreter,
    });
    setChildrenBirthDateBeingEditted(formatDateToUsa(birthDate)!);
    setChildrenInterestsBeingEditted(preferredCategories.map((interest) => ({
      value: interest.id, label: interest.name,
    })));
    setChildrenNameBeingEditted(name);
    setChildrenPeriodBeingEditted(preferredPeriods.map((period) => ({
      value: period, label: period,
    })));
    setChildrenObservationBeingEditted(observation ?? '');
    setChildrenneedsInterpreterBeingEditted(needsInterpreter);
    setChildrenDetailsModalShow(true);
  }

  function handleOpenChildrenRemoveModal(childrenId: string) {
    setChildrenBeingRemoved(childrenId);
    setChildrenRemoveModalShow(true);
  }

  const addNewChildren = useCallback(async () => {
    const clonedChildrens: UserChildType[] = JSON.parse(JSON.stringify(childrens));
    const isSomeRequiredFieldNull = (!childrenNameBeingAdded || !childrenBirthDateBeingAdded);
    const doesChildrenAlreadyExists = clonedChildrens.some((child) => child.name === childrenNameBeingAdded) && !(childrenNameBeingAdded === childrenInfoBeingShow.name);

    if (doesChildrenAlreadyExists) {
      toast.error('Já existe uma criança com esse nome!');
      return;
    }

    if (isSomeRequiredFieldNull) {
      toast.error('Nome e data de nascimento devem ser preenchidos!');
      return;
    }

    if (!isDateBeforeToday(childrenBirthDateBeingAdded)) {
      toast.error('A data de nascimento é inválida!');
      return;
    }

    await apiCall({
      apiToCall: kidsService.createKid,
      queryParams: { userId: id },
      reqBody: JSON.stringify({
        name: childrenNameBeingAdded,
        birthDate: formatDate(childrenBirthDateBeingAdded),
        preferredPeriods: childrenPeriodBeingAdded.map((period) => period.value),
        preferredCategories: childrenInterestsBeingAdded.map((ctg) => ctg.value),
        observation: childrenObservationBeingAdded,
        needsInterpreter: childrenneedsInterpreterBeingAdded,
      }),
      onStartLoad: () => setIsLoadingAfterChildrenAction(true),
      onEndLoad: () => setIsLoadingAfterChildrenAction(false),
      actionAfterResponse: async (response) => {
        if (!response.success) {
          toast.error('Não foi possíve adicionar a criança. Por favor, tente novamente.');
          return;
        }
        setAddChildrenModalShow(false);
        setChildrenBirthDateBeingAdded('');
        setChildrenInterestsBeingAdded([]);
        setChildrenNameBeingAdded('');
        setChildrenPeriodBeingAdded([]);
        await loadForm();
        toast.success('Criança adicionada com sucesso');
      },
      catchMessage: 'Não foi possíve adicionar a criança. Por favor, tente novamente.',
    })
  }, [apiCall, childrenBirthDateBeingAdded, childrenInfoBeingShow.name, childrenInterestsBeingAdded, childrenNameBeingAdded, childrenneedsInterpreterBeingAdded, childrenObservationBeingAdded, childrenPeriodBeingAdded, childrens, id, loadForm]);

  const editChildren = useCallback(async () => {
    const clonedChildrens: UserChildType[] = JSON.parse(JSON.stringify(childrens));
    const isSomeRequiredFieldNull = (!childrenNameBeingEditted || !childrenBirthDateBeingEditted);
    const doesChildrenAlreadyExists = clonedChildrens.some((child) => child.name === childrenNameBeingEditted) && !(childrenNameBeingEditted === childrenInfoBeingShow.name);

    if (doesChildrenAlreadyExists) {
      toast.error('Já existe uma criança com esse nome!');
      return;
    }

    if (isSomeRequiredFieldNull) {
      toast.error('Nome e data de nascimento devem ser preenchidos!');
      return;
    }

    if (!isDateBeforeToday(childrenBirthDateBeingEditted)) {
      toast.error('A data de nascimento é inválida!');
      return;
    }

    await apiCall({
      apiToCall: kidsService.updateKid,
      queryParams: { userId: id, kidId: childrenInfoBeingShow.id },
      reqBody: JSON.stringify({
        name: childrenNameBeingEditted,
        birthDate: formatDate(childrenBirthDateBeingEditted),
        preferredPeriods: childrenPeriodBeingEditted.map((period) => period.value),
        preferredCategories: childrenInterestsBeingEditted.map((ctg) => ctg.value),
        observation: childrenObservationBeingEditted,
        needsInterpreter: childrenneedsInterpreterBeingEditted,
      }),
      onStartLoad: () => setIsLoadingAfterChildrenAction(true),
      onEndLoad: () => setIsLoadingAfterChildrenAction(false),
      actionAfterResponse: async (response) => {
        if (!response.success) {
          toast.error('Não foi possíve editar a criança. Por favor, tente novamente.');
          return;
        }
        setChildrenBirthDateBeingEditted('');
        setChildrenInterestsBeingEditted([]);
        setChildrenNameBeingEditted('');
        setChildrenPeriodBeingEditted([]);
        setChildrenInfoBeingShow({} as ChildrenInfoBeingShownType);
        setChildrenDetailsModalShow(false);
        await loadForm();
        toast.success('Criança editada com sucesso');
      },
      catchMessage: 'Não foi possíve editar a criança. Por favor, tente novamente.',
    })
  }, [apiCall, childrenBirthDateBeingEditted, childrenInfoBeingShow.id, childrenInfoBeingShow.name, childrenInterestsBeingEditted, childrenNameBeingEditted, childrenneedsInterpreterBeingEditted, childrenObservationBeingEditted, childrenPeriodBeingEditted, childrens, id, loadForm]);

  const removeChildren = useCallback(async (children: string) => {
    await apiCall({
      apiToCall: kidsService.deleteKid,
      queryParams: { userId: id, kidId: children },
      onStartLoad: () => setIsLoadingAfterChildrenAction(true),
      onEndLoad: () => setIsLoadingAfterChildrenAction(false),
      actionAfterResponse: async (response) => {
        if (!response.success) {
          toast.error('Não foi possíve remover a criança. Por favor, tente novamente.');
          return;
        }
        setChildrenRemoveModalShow(false);
        setChildrenBeingRemoved('');
        await loadForm();
        toast.success('Criança removida com sucesso');
      },
      catchMessage: 'Não foi possíve remover a criança. Por favor, tente novamente.',
    })
  }, [apiCall, id, loadForm]);

  return {
    addChildrenModalShow,
    setAddChildrenModalShow,
    childrenDetailsModalShow,
    childrenInfoBeingShow,
    addNewChildren,
    removeChildren,
    handleOpenChildrenDetailsModal,
    setChildrenNameBeingAdded,
    setChildrenObservationBeingAdded,
    setChildrenBirthDateBeingAdded,
    setChildrenPeriodBeingAdded,
    setChildrenInterestsBeingAdded,
    childrenNameBeingAdded,
    childrenObservationBeingAdded,
    childrenBirthDateBeingAdded,
    childrenPeriodBeingAdded,
    childrenInterestsBeingAdded,
    handleOpenChildrenRemoveModal,
    childrenBeingRemoved,
    childrenRemoveModalShow,
    editChildren,
    childrenNameBeingEditted,
    setChildrenNameBeingEditted,
    childrenObservationBeingEditted,
    setChildrenObservationBeingEditted,
    childrenBirthDateBeingEditted,
    setChildrenBirthDateBeingEditted,
    childrenPeriodBeingEditted,
    setChildrenInterestsBeingEditted,
    childrenInterestsBeingEditted,
    setChildrenPeriodBeingEditted,
    setChildrenRemoveModalShow,
    setChildrenDetailsModalShow,
    isLoadingAfterChildrenAction,
    setChildrenneedsInterpreterBeingAdded,
    setChildrenneedsInterpreterBeingEditted,
    childrenneedsInterpreterBeingAdded,
    childrenneedsInterpreterBeingEditted,
  };
}
